.react-time-picker__wrapper {
  border-radius: 15px;
  border-color: black;
  border-width: 2px;
  padding: 4px 15px;
}

.react-time-picker__wrapper * {
  color: black;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
