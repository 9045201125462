.admin-current-status-section * {
  color: black;
  font-weight: 700;
  font-size: 20px;
}

.admin-current-status-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.admin-current-status-section button {
  margin: 20px 0;
  padding: 5px 10px;
  background-color: var(--dark-green);
  border: none;
  color: white;
  border-radius: 10px;
}

.admin-current-status-section button:disabled {
  background-color: lightgray;
}

.admin-current-status-section .first-line {
  display: flex;
  align-items: center;
  padding: 30px;
}

.admin-current-status-section .first-line .current-status-label {
  margin-right: 20px;
}

.admin-current-status-section .first-line .react-toggle {
  margin-right: 5px;
}

.admin-current-status-section .first-line .open-close-label .closed {
  color: red;
}

.admin-current-status-section .second-line {
  display: flex;
  align-items: center;
}

.admin-current-status-section .second-line .return-time-label {
  margin-right: 20px;
}

.admin-schedule-section * {
  color: black;
  font-weight: 700;
  font-size: 20px;
}

.admin-schedule-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.admin-schedule-section .button-wrapper {
  margin: 0px 0px 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-schedule-section button {
  padding: 5px 10px;
  background-color: var(--dark-green);
  border: none;
  color: white;
  border-radius: 10px;
}

.admin-schedule-section button:disabled {
  background-color: lightgray;
}

.admin-schedule-section .day-section {
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.admin-schedule-section .day-section .day-label {
  /* width: 120px; */
  flex-grow: 1;
  text-align: end;
  display: flex;
  justify-content: flex-end;
}

.admin-schedule-section .day-section .open-close-times {
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-schedule-section .day-section .open-close-times .react-time-picker {
  margin: 0 10px;
}

.admin-schedule-section .day-section .toggle-status {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.admin-schedule-section .day-section .toggle-status .closed {
  color: red;
}

.admin-schedule-section .day-section .toggle-status .react-toggle {
  margin-right: 5px;
}

@media only screen and (max-width: 590px) {
  .admin-schedule-section .day-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
  }

  .admin-schedule-section .day-section .day-label {
    width: auto;
  }

  .admin-schedule-section .day-section .open-close-times {
    order: 2;
  }

  .admin-schedule-section .day-section .toggle-status {
    order: 1;
    margin: 10px 30px;
  }
}
