:root {
  --dark-green: rgba(0, 146, 69, 1);
  --mid-green: rgba(84, 166, 35, 1);
  --light-green: rgba(204, 220, 11, 1);
  --background-grey: rgba(242, 242, 242, 1);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background-color: var(--background-grey);
}
